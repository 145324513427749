<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">ข้อมูลงาน</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3 pb-10">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>เรื่อง</v-subheader>
                        </v-col>
                        <v-col cols="8">
                            <div class="pt-3">{{ topic }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>วันที่แจ้งงาน</v-subheader>
                        </v-col>
                        <v-col cols="8">
                            <div class="pt-3">{{ jobDate }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>ผู้แจ้งงาน</v-subheader>
                        </v-col>
                        <v-col cols="8">
                            <div class="pt-3">{{ staffFirstname }} {{ staffLastname }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>ประเภทงาน</v-subheader>
                        </v-col>
                        <v-col cols="8">
                            <div class="pt-3">{{ jobTypeName }}</div>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col cols="2">
                            <v-subheader>วันที่กำหนดเสร็จงาน</v-subheader>
                        </v-col>
                        <v-col cols="8">
                          <div class="pt-3">{{ dueDate }}</div>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>รายละเอียด</v-subheader>
                        </v-col>
                        <v-col cols="8">
                          <div class="pt-3" v-html="description"></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>แนบลิ้งค์ไฟล์เอกสาร</v-subheader>
                        </v-col>
                        <v-col cols="8">
                          <div class="pt-3"><a :href="linkFile" target="_blank">{{ linkFile }}</a></div>
                          <div class="pt-3"><a :href="linkFile2" target="_blank">{{ linkFile2 }}</a></div>
                          <div class="pt-3"><a :href="linkFile3" target="_blank">{{ linkFile3 }}</a></div>
                          <div class="pt-3"><a :href="linkFile4" target="_blank">{{ linkFile4 }}</a></div>
                          <div class="pt-3"><a :href="linkFile5" target="_blank">{{ linkFile5 }}</a></div>
                        </v-col>
                    </v-row>
                    <v-divider class="mx-4 mb-5" />
                    
                </v-container>
           

              <v-card-title>
                  <span class="subtitle-1">มอบหมาย</span>
              </v-card-title>
              <v-divider class="mx-4 mb-5" />
              
              <v-container>
                <v-card outlined>
                  <v-data-table
                      :headers="headers"  
                      :items="assignData"
                      disable-sort
                      disable-filtering
                      hide-default-footer	
                  >
                  <template v-slot:item.status_name="{ item }">
                        <v-chip
                        v-if="item.job_status == 4"
                        color="green"
                        text-color="white"
                        >
                        {{ item.status_name }}
                        </v-chip>
                        <span v-else>{{ item.status_name }}</span>
                    </template>
                  </v-data-table>
                </v-card>
              </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    props: ['jobId'],
    data: () => ({
        storeUser: null,
        topic: '',
        dueDate: new Date().toISOString().substr(0, 10),
        description: '',
        linkFile: '',
        linkFile2: '',
        linkFile3: '',
        linkFile4: '',
        linkFile5: '',
        formChkErrors: false,
        menu: false,
        jobTypeName: '',
        jobDate: '',
        assignData: [],
        staffFirstname: '',
        staffLastname: '',
        requestBy: null,
        headers: [
            {
                text: "มอบหมายให้",
                value: "firstname",
                // class: "text-subtitle-1 font-weight-bold grey--text text--darken-4"
            },
            {
                text: "เรื่อง",
                value: "topic",
                // class: "text-subtitle-1 font-weight-bold grey--text text--darken-4"
            },
            {
                text: "ประเภทงาน",
                value: "type_name"
            },
            {
                text: "สถานะ",
                value: "status_name"
            }
        ],
    }),
    created() {
        this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getData()
      await this.getAssignToData()
      this.$store.commit('setLoading', false)
    },
    methods: {
        async getData() {
          try {
            const res = (await Api().get('jobrequest/' + this.jobId)).data;

            if (res.status == 'success') {
              this.topic = res.data.topic
              this.jobType = res.data.job_type
              this.dueDate = res.data.due_date
              this.description = res.data.description
              this.linkFile = res.data.link_file
              this.linkFile2 = res.data.link_file2
              this.linkFile3 = res.data.link_file3
              this.linkFile4 = res.data.link_file4
              this.linkFile5 = res.data.link_file5
              this.jobTypeName = res.data.type_name
              this.jobDate = res.data.job_date
              this.staffFirstname = res.data.firstname
              this.staffLastname = res.data.lastname
              this.requestBy = res.data.request_by
            } else {
              console.log(res.data);
            }

          } catch (err) {
            console.log(err.message);
          }
        },
        async getAssignToData() {
          
          try {
            const res = (await Api().get('jobassignto/' + this.jobId)).data;

            if (res.status == 'success') {
              this.assignData = res.data
            } else {
              console.log(res.data);
            }

          } catch (err) {
            console.log(err.message);
          }
        }
    }
};
</script>